<style>

</style>
<script>
import { VclBulletList } from 'vue-content-loading';
import simplebar from "simplebar-vue";
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id'],
  components: {
    VclBulletList,
    simplebar
  },
  computed: {
    ...mapGetters(['getDTLocale']),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/protocol-preview`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.entries = data.entries;
          if(this.entries.length > 0) this.delayedScroll();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    delayedScroll() {
      setTimeout(() => {
        let element = this.$refs.protocolContainer;
        if(element.scrollElement.offsetHeight >= 250 && element.scrollElement.scrollHeight > element.scrollElement.offsetHeight) {
          element.scrollElement.scrollTo(0, element.scrollElement.scrollHeight);
        }
      }, 150);
    },
    openProtocol(event) {
      this.$parent.$emit('tabChangeRequest', 'MProtocol');
      event.preventDefault();
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      entries: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="card">
        <template v-if="!entries.length">
          <div class="card-body">
            <div class="text-center allign-center mt-4 mb-4">
              <h3 class="text-uppercase text-muted">{{ $t('banmanager.view.overview.protocol.empty') }}</h3>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body">
            <simplebar style="max-height: 250px;" ref="protocolContainer">
            <ul class="verti-timeline list-unstyled">
              <li class="event-list" v-for="entry in entries" :key="entry.id">
                <div class="event-timeline-dot">
                  <i class="fad fa-circle"></i>
                </div>
                <div class="media">
                  <div class="media-body">
                    <div>
                      <h5 class="font-size-15 text-dark">
                        <template v-if="entry.user">
                          <router-link :to="{name: 'profile', params: {cftools_id: entry.user.cftools_id}}">

                            {{ entry.user.display_name }}
                          </router-link>
                        </template>
                        <b class="text-lowercase text-wrap text-code ml-2">
                          {{ entry.action }}
                        </b>
                        <small class="float-left">
                          <i class="fad fa-clock"></i>
                          {{ $d(parseDate(entry.date), 'datetime', getDTLocale()) }}
                        </small>
                      </h5>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            </simplebar>
          </div>
          <a href="#" v-on:click="openProtocol($event)" class="text-muted text-uppercase text-center">
            <div class="card-footer">
              <h4>
                {{ $t('banmanager.view.overview.protocol.link') }}
              </h4>
            </div>
          </a>
        </template>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card">
        <div class="card-body">
          <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
        </div>
      </div>
    </template>
  </div>
</template>
